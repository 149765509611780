import { Grid } from '@mui/material'

import { Icon } from '@/libs/common'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { AddTemplateItem } from '@/pages/modal-page/libs/components/template-management/libs/components/add-template-item'

// TODO: Enable different templates when they are ready
const AddTemplate = () => {
  const addCopyTradeLink = `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.COPY_TRADE_TEMPLATE}?action=add-copytrade`

  const addManualBuyLink = `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.MANUAL_BUY_TEMPLATE}?action=add`

  const addSnipeLink = `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.SNIPE_TEMPLATE}?action=add-snipe`

  const addScrapeLink = `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.ADD_NEW_SCRAPE}`

  return (
    <Grid container gap={2} flexDirection="column">
      <AddTemplateItem
        title="Copy trade"
        icon={<Icon name={IconName.COPY_LARGE} />}
        link={addCopyTradeLink}
      />

      <AddTemplateItem
        title="Manual Buy"
        icon={<Icon name={IconName.SHOPPING_CART_LARGE} />}
        link={addManualBuyLink}
      />

      <AddTemplateItem title="Scrape" icon={<Icon name={IconName.SCRAPE} />} link={addScrapeLink} />

      <AddTemplateItem
        title="Snipe"
        icon={<Icon name={IconName.SNIPE_LARGE} />}
        link={addSnipeLink}
      />
    </Grid>
  )
}

export { AddTemplate }
