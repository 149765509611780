import { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Grid, InputLabel } from '@mui/material'
import cls from 'classnames'

import { SelectWallet } from '@/components/select-wallet'
import {
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  HiddenInputs,
  StepInput,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { IconName } from '@/libs/enums'
import { TSelectOption } from '@/libs/types/select-option.type'
import { TUserWallet } from '@/libs/types/user.type'
import { TManualBuyValidationSchema } from '@/libs/validations/types'
import { useAppSelector } from '@/store'

import styles from '../../styles.module.scss'

const ProfitSettingsTabs: TSelectOption<string>[] = [
  { value: 'sellInSteps', label: 'Sell in steps' },
  { value: 'sellOnProfit', label: 'Sell on profit' },
  { value: 'trailingSell', label: 'Trailing sell' },
]

const ManualTemplateSellTab = ({
  selectedWalletAddresses,
}: {
  selectedWalletAddresses: string[]
}) => {
  const [isAutoSellSettings, setIsAutoSellSetting] = useState(false)
  const [walletsOnProfit, setWalletsOnProfit] = useState<number[]>([])
  const [profitTab, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const { control } = useFormContext<TManualBuyValidationSchema>()

  const handleProfitTabChange = useCallback((_: React.BaseSyntheticEvent, newValue: string) => {
    setProfitTab(newValue)
  }, [])

  const selectedWallets = useMemo(() => {
    if (selectedWalletAddresses?.length && userWallets) {
      return userWallets.filter((wallet: TUserWallet) =>
        selectedWalletAddresses.includes(wallet.address),
      )
    }
    return []
  }, [selectedWalletAddresses, userWallets])

  return (
    <HiddenInputs
      expanded={isAutoSellSettings}
      trigger={
        <Controller
          name="template.autoSell"
          control={control}
          render={({ field: { ref, onChange, ...field } }) => (
            <SwitchInline
              label="Auto Sell"
              tooltipInfo="The bot will automatically sell tokens when the settings you specified are triggered."
              {...field}
              onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                setIsAutoSellSetting(checked)
                onChange(checked)
              }}
            />
          )}
        />
      }
    >
      <Grid container rowGap={2} flexDirection="column">
        <Controller
          name="template.sellPriority"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              placeholder="Enter gwei amount"
              isNumeric
              label="Sell Priority"
              tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
              endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
              onAutoChange={() => {
                //TO DO: added auto switch
              }}
              {...field}
            />
          )}
        />
        <div className={styles.dividerContainer}>
          <Typography variant="body2" textColor="grey">
            Profit
          </Typography>
          <div className={styles.divider}></div>
        </div>
        <Controller
          name="template.walletsToSellOnProfit"
          control={control}
          render={({ field: { ref, value, onChange, ...field } }) => (
            <SelectWallet
              tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
              label="Wallets to sell on Profit"
              isMulti
              onChange={(value: any) => {
                setWalletsOnProfit(value)
                onChange(value)
              }}
              isSelectDefaultWallet={false}
              wallet={value}
              wallets={userWallets}
              {...field}
            />
          )}
        />

        <ButtonGroupRadio
          onChange={handleProfitTabChange}
          value={profitTab}
          exclusive
          className={styles.group}
        >
          {ProfitSettingsTabs.map((tab) => (
            <ButtonGroupRadioButton
              value={tab.value}
              key={tab.value}
              className={cls({ [styles.bgGrey]: tab.value === profitTab })}
            >
              {tab.label}
            </ButtonGroupRadioButton>
          ))}
        </ButtonGroupRadio>

        {profitTab === ProfitSettingsTabs[0].value && (
          <div>
            <div className={styles.labelContent}>
              <InputLabel className={styles.label}>
                <Typography variant="body2" textColor="light-grey">
                  Sell in steps
                </Typography>
              </InputLabel>
              <TooltipIcon
                info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%."
                marginLeft={5}
              />
            </div>
            {walletsOnProfit.length !== 0 ? (
              <div className={styles.stepWrapper}>
                {walletsOnProfit.map((wallet, index) => (
                  <Controller
                    key={wallet}
                    name={`w${wallet}` as keyof TManualBuyValidationSchema}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <StepInput
                        wallet="#1"
                        balance="12.58 ETH"
                        className={cls({
                          [styles.firstInput]: index === 0,
                          [styles.lastInput]: index === walletsOnProfit.length - 1,
                        })}
                        {...field}
                      />
                    )}
                  />
                ))}
              </div>
            ) : (
              <Grid marginTop={1}>
                <Typography variant="body1">No wallets selected</Typography>
              </Grid>
            )}
          </div>
        )}
        {profitTab === ProfitSettingsTabs[1].value && (
          <Grid container flexWrap="nowrap" columnGap={3}>
            <Controller
              name="template.sellOnProfit"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder="Enter sell on profit"
                  isNumeric
                  label="Sell on Profit"
                  adornmentText="%"
                  tooltipInfo="Threshold of profit on token position. Sells when the position reaches the specified profit amount."
                  {...field}
                />
              )}
            />
            <Controller
              name="template.sellOnProfitAmount"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder="Enter sell profit amount"
                  isNumeric
                  label="Sell Profit Amount"
                  adornmentText="%"
                  tooltipInfo="The percentage of tokens that will be sold when the Take Profit is triggered."
                  {...field}
                />
              )}
            />
          </Grid>
        )}
        {profitTab === ProfitSettingsTabs[2].value ? (
          <>
            <Controller
              name="template.walletsToTrailingSell"
              control={control}
              render={({ field: { ref, value, ...field } }) => (
                <SelectWallet
                  isMulti
                  wallet={value}
                  wallets={selectedWallets}
                  label="Wallets to trailing sell"
                  tooltipInfo="tooltipInfo"
                  isSelectDefaultWallet={false}
                  {...field}
                />
              )}
            />
            <Controller
              name="template.trailingSell"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder="Enter trailing sell"
                  isNumeric
                  label="Trailing Sell"
                  adornmentText="%"
                  tooltipInfo="tooltipInfo"
                  {...field}
                />
              )}
            />
          </>
        ) : (
          <>
            <div className={styles.dividerContainer}>
              <Typography variant="body2" textColor="grey">
                Loss
              </Typography>
              <div className={styles.divider}></div>
            </div>
            <Controller
              name="template.walletsToSellOnStopLoss"
              control={control}
              render={({ field: { ref, value, ...field } }) => (
                <SelectWallet
                  tooltipInfo="Select how many wallets will sell when the loss threshold is reached. For example, 1st wallet will sell at 20% loss, 2nd wallet will sell at 30% loss."
                  label="Wallets to sell on Stop Loss"
                  isMulti
                  wallet={value}
                  wallets={userWallets}
                  isSelectDefaultWallet={false}
                  {...field}
                />
              )}
            />
            <Grid container flexWrap="nowrap" columnGap={3}>
              <Controller
                name="template.stopLoss"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder="Enter sell on loss %"
                    isNumeric
                    label="Stop Loss"
                    tooltipInfo="Threshold of loss on token position, exceeding which tokens will be sold."
                    adornmentText="%"
                    {...field}
                  />
                )}
              />
              <Controller
                name="template.stopLossAmount"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder="Enter sell on loss amount"
                    isNumeric
                    label="Stop Loss Amount"
                    tooltipInfo="The percentage of tokens that will be sold when the stop loss is triggered."
                    adornmentText="%"
                    {...field}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </HiddenInputs>
  )
}

export { ManualTemplateSellTab }
