import { ChangeEvent, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { ToggleButtonGroupProps } from '@mui/material'
import cn from 'classnames'

import { Input, InputWithBuyAmount, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { IconName } from '@/libs/enums'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { TBuyTemplate } from '@/libs/types/template'
import { TBuyValidationSchema } from '@/libs/validations/types'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = ToggleButtonGroupProps & {
  isDisabled: boolean
  currentTemplate: TBuyTemplate | null
  availableTokens?: number
  spendOptions?: any[]
}

enum ELastUsedAmountInput {
  SPEND = 'Spend',
  RECEIVE = 'Receive',
}

const buyReceiveStaticInfo = {
  spend: {
    label: 'Spend',
    placeholder: 'Enter amount to spend',
  },
  receive: {
    label: 'Receive',
    placeholder: 'Enter amount to receive',
  },
}

const SpendReceiveGroup = ({
  currentTemplate,
  isDisabled,
  availableTokens,
  spendOptions = [],
}: TProps) => {
  const {
    clearErrors,
    getValues,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useFormContext<TBuyValidationSchema>()
  const [lastUsedAmountInput, setLastUsedAmountInput] = useState<ELastUsedAmountInput | null>(null)

  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const currentTokenSimulation = useAppSelector(
    (state) => state.chain.currentTokenSimulationWebsocket,
  )

  const receiveValue = +watch('ordinaryBuy.receive')
  const templateSlippage = currentTemplate
    ? currentTemplate.setup.operation.setup.slippage
    : +watch('ordinaryBuy.slippage') / 100
  const minimumReceive = receiveValue - templateSlippage * receiveValue
  const staticInfo = buyReceiveStaticInfo

  useEffect(() => {
    if (!currentChain.nativeTokenPriceInUsd || !lastUsedAmountInput) return

    if (lastUsedAmountInput === ELastUsedAmountInput.SPEND) {
      const spendValue = getValues().ordinaryBuy.spend
      if (spendValue) {
        calculateReceive(spendValue)
      }
    } else if (lastUsedAmountInput === ELastUsedAmountInput.RECEIVE) {
      const receiveValue = getValues().ordinaryBuy.receive
      if (receiveValue) {
        calculateSpend(receiveValue)
      }
    }
  }, [currentChain])

  const calculateSpend = (value: string) => {
    if (!currentToken || !currentChain.nativeTokenPriceInUsd) return

    setValue('ordinaryBuy.receive', `${value}`, {
      shouldValidate: !!value,
    })
    if (!value) {
      setValue('ordinaryBuy.spend', '')
      clearErrors('ordinaryBuy')
      return
    }
    const coeff = +currentToken?.token.price / currentChain.nativeTokenPriceInUsd
    setValue(
      'ordinaryBuy.spend',
      +value ? convertScientificNotationNumber(+value * coeff, MAX_TRX_DECIMALS) : '',
      {
        shouldValidate: !!value,
      },
    )
  }

  const calculateReceive = (value: string) => {
    if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
    setValue('ordinaryBuy.spend', `${value}`, {
      shouldValidate: !!value,
    })
    if (!value) {
      setValue('ordinaryBuy.receive', '')
      clearErrors('ordinaryBuy')
      return
    }
    const coeff = currentChain.nativeTokenPriceInUsd / +currentToken.token.price
    setValue(
      'ordinaryBuy.receive',
      +value ? convertScientificNotationNumber(+value * coeff, MAX_TRX_DECIMALS) : '',
      {
        shouldValidate: true,
      },
    )
  }

  const handleChangeSpend = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
    setLastUsedAmountInput(ELastUsedAmountInput.SPEND)
    if (!e.target.value) {
      calculateReceive(e.target.value)
      return
    }
    const newValue = convertScientificNotationNumber(e.target.value, MAX_TRX_DECIMALS, true)
    calculateReceive(newValue)
  }

  const handleChangeReceive = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
    setLastUsedAmountInput(ELastUsedAmountInput.RECEIVE)
    if (!e.target.value) {
      calculateSpend(e.target.value)
      return
    }
    const newValue = convertScientificNotationNumber(e.target.value, MAX_TRX_DECIMALS, true)
    calculateSpend(newValue)
  }

  return (
    <div className={styles.container}>
      <div className={styles.spend}>
        <Controller
          name="ordinaryBuy.spend"
          control={control}
          render={({ field: { ref, onChange, ...field } }) => {
            if (!spendOptions.length) {
              return <></>
            }
            return (
              <InputWithBuyAmount
                label={staticInfo.spend.label}
                placeholder={staticInfo.spend.placeholder}
                isNumeric
                inputClassName={styles.input}
                onOptionSelect={(value: any) => {
                  const newValue = convertScientificNotationNumber(value, MAX_TRX_DECIMALS)
                  setLastUsedAmountInput(ELastUsedAmountInput.SPEND)
                  calculateReceive(newValue)
                }}
                endAdornment={
                  <EndAdornment
                    label={currentChain.chainName}
                    icon={(IconName as any)[currentChain.iconName]}
                    isDisabled={isDisabled}
                  />
                }
                radioGroupName="amount"
                disabled={isDisabled}
                defaultOptions={spendOptions}
                onChange={handleChangeSpend}
                error={!!errors.ordinaryBuy?.spend?.message}
                {...field}
              />
            )
          }}
        />
        <div className={styles.available}>
          <Typography variant="body2" className={styles.param}>
            Available:
          </Typography>
          <Typography variant="body2" className={styles.value}>
            {(availableTokens || 0).toLocaleString('en-US', {
              maximumFractionDigits: 5,
            })}{' '}
            ETH
          </Typography>
        </div>
      </div>
      <div className={styles.union}>
        <Controller
          name="ordinaryBuy.receive"
          control={control}
          render={({ field: { ref, onChange, ...field } }) => (
            <Input
              label={staticInfo.receive.label}
              placeholder={staticInfo.receive.placeholder}
              isNumeric
              className={styles.input}
              endAdornment={
                <EndAdornment
                  label={currentToken?.token.symbol}
                  imageUrl={currentToken?.token.images.small}
                  isDisabled={isDisabled}
                />
              }
              disabled={isDisabled}
              onChange={handleChangeReceive}
              error={!!errors.ordinaryBuy?.receive?.message}
              {...field}
            />
          )}
        />
        <div className={cn(styles.infoContainer, { [styles.disabled]: isDisabled })}>
          <div className={styles.info}>
            <Typography variant="body2" className={styles.param}>
              DEX:
            </Typography>
            <Typography variant="body2" className={styles.value}>
              {currentTokenSimulation?.l.dn}
            </Typography>
          </div>
          <div className={styles.info}>
            <Typography variant="body2" className={styles.param}>
              Minimum received:
            </Typography>
            <Typography variant="body2" className={styles.value}>
              {minimumReceive ? formatTokenPrice(minimumReceive.toString()).formatted : '-'}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SpendReceiveGroup }
