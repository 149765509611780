import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ComingSoonOverlay } from '@/components/coming-soon-overlay'
import { ListButton } from '@/libs/common'
import { Typography } from '@/libs/common/typography'
import { chainsConfig } from '@/libs/configs/chains.config'
import { DEFAULT_TOKEN, DEFAULT_TOKEN_BSC } from '@/libs/configs/default-token.config.ts'
import { AppRoute, IconName } from '@/libs/enums'
import { TChainConfig } from '@/libs/types/chain.type'
import { useAppDispatch } from '@/store'
import { setCurrentChain } from '@/store/slices/chain.slice'

import styles from './styles.module.scss'

type TProperty = { test?: string }

const SwitchChain: FC<TProperty> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { mode } = useParams()

  const onChange = (newChain: TChainConfig) => {
    dispatch(setCurrentChain(newChain))
    localStorage.chain = JSON.stringify(newChain)
    if (newChain.id === 1) {
      navigate(`${AppRoute.DASHBOARD}/${mode}?token=${DEFAULT_TOKEN}`)
    } else {
      navigate(`${AppRoute.DASHBOARD}/${mode}?token=${DEFAULT_TOKEN_BSC}`)
    }
    location.reload()
  }

  return (
    <div className={styles.switchChainContainer}>
      {/* <ComingSoonOverlay /> */}
      <div className={styles.titleWrapper}>
        <Typography variant="h1" className={styles.title}>
          Switch Chain
        </Typography>
      </div>

      <div className={styles.chainsList}>
        {chainsConfig.map((chain) => (
          <ListButton
            iconName={(IconName as any)[chain.iconName]}
            title={`${chain.label} Chain`}
            description={chain.description}
            onClick={() => {
              onChange(chain)
            }}
            key={chain.iconName}
          />
        ))}
      </div>
    </div>
  )
}

export { SwitchChain }
