import { FC, useState } from 'react'

import Grid from '@mui/material/Grid'
import MUIInput, { InputProps as MUIInputProps } from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import cn from 'classnames'

import { Icon, Tooltip, Typography } from '@/libs/common/index'
import { IconName } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import { TooltipIcon } from '../tooltip-icon'
import { AdormentError } from './components/components'
import { getInputId } from './helpers/getInputId'
import styles from './styles.module.scss'

type TInputProps = MUIInputProps & {
  label?: string
  labelTooltipTitle?: string
  idForInput?: string
  isCopy?: boolean
  isTransparent?: boolean
  maxLength?: number
  startInnerIconName?: ValueOf<typeof IconName>
  endInnerIconName?: ValueOf<typeof IconName>
  errorMessage?: string
  isNumeric?: boolean
  isHideInputCaption?: boolean
  tooltipInfo?: string
}

const Input: FC<TInputProps> = ({
  className,
  label,
  labelTooltipTitle,
  maxLength,
  placeholder = 'use placeholder prop',
  error,
  errorMessage,
  onChange,
  idForInput,
  value,
  isCopy = false,
  isHideInputCaption = false,
  isTransparent,
  startInnerIconName,
  endInnerIconName,
  isNumeric,
  tooltipInfo,
  ...props
}) => {
  idForInput = idForInput || getInputId(label)

  const [currentValue, setCurrentValue] = useState<string>('')

  const onChangeDecorator = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isNumeric && isNaN(Number(event.currentTarget.value))) {
      return
    }

    if (onChange) {
      onChange(event)
    } else {
      setCurrentValue(event.currentTarget.value)
    }
  }

  const copyToClipboard = () => {
    if (value && typeof value === 'string') {
      navigator.clipboard.writeText(value)
    } else if (currentValue) {
      navigator.clipboard.writeText(currentValue)
    }
  }

  return (
    <Stack className={styles.container} spacing={1}>
      <Grid
        container
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        width={tooltipInfo ? 'min-content' : '100%'}
      >
        {label && !labelTooltipTitle && (
          <InputLabel className={styles.label} htmlFor={idForInput}>
            {label}
          </InputLabel>
        )}
        {label && labelTooltipTitle && (
          <Tooltip title={labelTooltipTitle}>
            <InputLabel className={styles.label} htmlFor={idForInput}>
              {label}
            </InputLabel>
          </Tooltip>
        )}
        {isCopy && (
          <Typography
            className={styles.copyButton}
            onClick={copyToClipboard}
            variant="body2"
            isEmphasize
          >
            Copy
          </Typography>
        )}
        {tooltipInfo && <TooltipIcon info={tooltipInfo} marginLeft={5} />}
      </Grid>

      <MUIInput
        id={label && idForInput}
        className={cn(styles.root, className, {
          [styles['Mui-error']]: error,
          [styles.transparent]: isTransparent,
        })}
        placeholder={placeholder}
        startAdornment={
          startInnerIconName && (
            <InputAdornment position="start">
              <Icon name={startInnerIconName} />
            </InputAdornment>
          )
        }
        endAdornment={
          error ? (
            <AdormentError />
          ) : endInnerIconName ? (
            <InputAdornment position="end">
              <Icon name={endInnerIconName} />
            </InputAdornment>
          ) : (
            false
          )
        }
        onChange={onChangeDecorator}
        inputProps={{ maxLength }}
        value={value || currentValue}
        autoComplete="off"
        {...props}
      />
      {(errorMessage || maxLength) && !isHideInputCaption && (
        <div className={styles.inputCaption}>
          {errorMessage && (
            <Typography variant="caption" className={styles.errorMessage}>
              {errorMessage}
            </Typography>
          )}
          <div></div>
          {maxLength && (
            <Typography variant="caption" className={styles.counter}>
              {value ? `${value}`.length : currentValue.length}/{maxLength}
            </Typography>
          )}
        </div>
      )}
    </Stack>
  )
}

export { Input, type TInputProps }
