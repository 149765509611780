import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import * as yup from 'yup'

import { createBuyTemplate, updateBuyTemplate } from '@/api/templates'
import { CustomToast } from '@/components/custom-toast'
import { Button, Input, Tabs } from '@/libs/common'
import {
  AppMode,
  AppRoute,
  EManualSettingsSection,
  ETemplateType,
  ManualBuyTemplateAction,
} from '@/libs/enums'
import { createBuyFormStateFromTemplate } from '@/libs/helper/buy/createBuyFormStateFromTemplate'
import { createManualBuyPayload } from '@/libs/helper/buy/createManualBuyPayload'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { validateRequiredFields } from '@/libs/helper/validateRequiredFields'
import { TBuyTemplate } from '@/libs/types/template'
import { TWallet } from '@/libs/types/transaction'
import { getManualBuyValidationSchema } from '@/libs/validations/buy'
import { getTemplateActionTitle } from '@/pages/modal-page/libs/helpers'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserBuyTemplates } from '@/store/slices/user.slice'

import { ManualTemplateBuyTab } from './components/buy-tab'
import { ManualTemplateSafetyTab } from './components/safety-tab'
import { ManualTemplateSellTab } from './components/sell-tab/sell-tab'
import { MANUAL_BUY_CHAIN_SETTINGS } from './libs/constants'
import styles from './styles.module.scss'

const ManualBuyTemplate: FC = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const { chainName, iconName, chainSymbol } = currentChain

  const userData = useAppSelector((state) => state.user.userData)
  const buyTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const defaultUserPriorities = useAppSelector((state) => state.user.defaultPriorities)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { id: templateId } = useParams()

  const { setModalProps } = useModal()

  const templateToEdit = useMemo(
    () => buyTemplates?.find((item) => item.id === templateId),
    [buyTemplates],
  )

  const [isLoading, setIsLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [isContinueDisabled, setIsContinueDisabled] = useState(true)
  const [isInit, setIsInit] = useState(true)

  const isLastMainTab = currentTab === Object.values(EManualSettingsSection).length - 1
  const schema = yup.object({
    name: yup.string().required().min(4).max(64),
    template: getManualBuyValidationSchema(0, { excludeBuyFields: true }),
  })
  const advancedBuyDefaultValues = {
    buyPriority: defaultUserPriorities.buy_priority ?? '',
    approvePriority: defaultUserPriorities.approve_priority ?? '',
    minPercentTokenOrFail: '',
    maxTxOrFail: false,
  }
  const shieldsDefaultValues = {
    buy_tax: '',
    buy_amount: '',
    sell_tax: '',
    minimum_liquidity: '',
    maximum_liquidity: '',
    maximum_market_cap: '',
  }
  const defaultWallet = !userWallets ? null : userWallets.find((wallet) => wallet.is_default)
  const ordinaryBuyDefaultValues = useMemo(() => {
    return {
      privateTransaction: true,
      degenChadMode: false,
      slippage: 50,
      selectedWallets: defaultWallet ? [defaultWallet.address] : [],
      // onLimit: {
      //   dip: '',
      //   marketcap: '',
      //   price: '',
      //   triggerPricePercent: '',
      //   expiration: '',
      // },
      buy_amount: convertScientificNotationNumber(
        templateToEdit?.setup?.operation?.setup?.buy_amount ?? ``,
      ),
    }
  }, [userWallets])

  const defaultValues = {
    name: '',
    template: {
      ordinaryBuy: ordinaryBuyDefaultValues,
      advancedBuy: advancedBuyDefaultValues,
      shields: shieldsDefaultValues,
      transferOnBlacklist: false,
      antiBlacklist: false,
      antiRug: false,
      antiRugThreshold: 0,
      sellRugExtraTip: '',
      autoSell: false,
      sellPriority: '',
      walletsToSellOnProfit: [] as string[],
      walletsToSellOnLoss: [] as string[],
      walletsToSellOnStopLoss: [] as string[],
      stopLoss: '',
      stopLossAmount: '',
      sellOnProfit: '',
      sellOnProfitAmount: '',
      walletsToTrailingSell: [],
      trailingSell: '',
    },
  }
  const formMethods = useForm({
    defaultValues:
      !!templateId && !!templateToEdit
        ? createBuyFormStateFromTemplate(templateToEdit)
        : defaultValues,
    resolver: yupResolver(schema) as any,
  })
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = formMethods

  const fieldsRequiredForBuy = watch([
    'template.ordinaryBuy.slippage',
    'template.ordinaryBuy.selectedWallets',
  ])
  const selectedWalletAddresses = watch('template.ordinaryBuy.selectedWallets')

  useEffect(() => {
    const action = templateId ? ManualBuyTemplateAction.EDIT : ManualBuyTemplateAction.ADD
    setModalProps({
      title: getTemplateActionTitle(action),
      titleProps: { className: styles.title },
      withBackButton: true,
    })
  }, [templateId])

  useEffect(() => {
    setIsContinueDisabled(validateRequiredFields(fieldsRequiredForBuy))
  }, [fieldsRequiredForBuy])

  useEffect(() => {
    setIsInit(() => false)
  }, [])

  const handleChangeMainTab = (value: number) => {
    setCurrentTab(value)
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!userWallets || !userData) return

    try {
      setIsLoading(true)

      const wallets: TWallet[] = []
      data.template.ordinaryBuy.selectedWallets.forEach((publicKey) => {
        const wallet = userWallets.find((item) => item.address === publicKey)

        if (wallet) {
          wallets.push({
            name: wallet.name,
            public_key: wallet.address,
          })
        }
      })

      const templateSetup = createManualBuyPayload({
        data: data.template,
        wallets,
        isTemplate: true,
      })

      if (!templateSetup) {
        throw new Error('Failed to create template')
      }

      const payload = {
        blockchain: templateSetup.network.blockchain,
        name: data.name,
        template: {
          type: ETemplateType.MANUAL_BUY,
          setup: templateSetup,
        },
      }

      let newTemplatesList: TBuyTemplate[] = []

      if (templateId) {
        if (payload.template.setup?.network && templateToEdit?.setup.network) {
          payload.template.setup.network.blockchain = templateToEdit.setup?.network.blockchain
          payload.blockchain = templateToEdit.setup.network.blockchain
        }
        const { data } = await updateBuyTemplate(templateId, payload as any)
        newTemplatesList = data.data
      } else {
        const { data } = await createBuyTemplate(payload as any)
        newTemplatesList = data.data
      }

      dispatch(setUserBuyTemplates(newTemplatesList))

      navigate(`${AppRoute.DASHBOARD}/${AppMode.PRO}`)

      CustomToast('success', `Your template was successfully ${templateId ? 'edited' : 'created'}`)
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setIsLoading(false)
    }
  }
  const chainTabNames = Object.keys(MANUAL_BUY_CHAIN_SETTINGS[chainName])

  return (
    <FormProvider {...formMethods}>
      <Grid className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowGap={3}>
            <Grid className={styles.settings} container rowGap={0.5}>
              <Controller
                name="name"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Input
                    label="Template Name"
                    placeholder="Enter template name"
                    className={styles.input}
                    error={!!errors.name?.message}
                    maxLength={64}
                    {...field}
                  />
                )}
              />

              <Tabs
                value={currentTab}
                handleChangeTab={handleChangeMainTab}
                tabs={[
                  {
                    label: EManualSettingsSection.BUY,
                    content: (
                      <ManualTemplateBuyTab
                        chainName={chainName}
                        templateId={templateId}
                        isInit={isInit}
                      />
                    ),
                  },
                  {
                    isDisabled: isContinueDisabled,
                    label: EManualSettingsSection.SAFETY,
                    content: (
                      <ManualTemplateSafetyTab
                        chainName={chainName}
                        iconName={iconName}
                        chainSymbol={chainSymbol}
                        templateId={templateId}
                      />
                    ),
                  },
                  {
                    label: EManualSettingsSection.SELL,
                    content: (
                      <ManualTemplateSellTab selectedWalletAddresses={selectedWalletAddresses} />
                    ),
                  },
                ].filter((tab) => chainTabNames.includes(tab.label) || !chainName)}
              />
            </Grid>
            <Grid container columnGap={3} flexWrap="nowrap">
              {!isLastMainTab && (
                <Button
                  styleVariant="black"
                  onClick={() => handleChangeMainTab(currentTab + 1)}
                  disabled={isContinueDisabled}
                >
                  Continue
                </Button>
              )}
              <Button
                type="submit"
                disabled={!isLastMainTab}
                isLoading={isLoading}
                checkOnAccountLock
              >
                {templateId ? 'Save' : 'Add'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </FormProvider>
  )
}

export { ManualBuyTemplate }
