import { FC, useEffect, useMemo, useState } from 'react'

import { getSetup } from '@/api/user'
import { BuyTab } from '@/components/buy-tab'
import { PairStats } from '@/components/pair-stats'
import { ProfitSellTab } from '@/components/profit-sell-tab'
import { SellTab } from '@/components/sell-tab'
import { TTabsProps, Tabs } from '@/libs/common/index'
import { ChainName, LocalStorageItem, TemplateGroup } from '@/libs/enums'
import { TTab } from '@/libs/types'
import { useAppDispatch, useAppSelector } from '@/store'
import { clearCurrentSelectedTemplate, setUserPrioritySettings } from '@/store/slices/user.slice'

import { SnipeTab } from './libs/components/snipe-tab'
// import { SnipeTab } from './libs/components/snipe-tab'
import styles from './styles.module.scss'

const excludeTabs: string[] = [TemplateGroup.SNIPE]

const BuySellSnipeProfitSell: FC<Omit<TTabsProps, 'tabs'>> = ({ className, ...props }) => {
  const dispatch = useAppDispatch()
  const { userCurrentSelectedTemplate, userData } = useAppSelector((state) => state.user)
  const chainName = useAppSelector((state) => state.chain.currentChain.chainName)
  const [value, setValue] = useState<number | undefined>()

  useEffect(() => {
    if (!userData) return
    const fetchSetup = async () => {
      const { data } = await getSetup(userData.user_id)

      if (data.data?.buy_priority) {
        const { buy_priority, sell_priority, approve_priority } = data.data
        const priorityParams = {
          buy_priority: buy_priority ? `${buy_priority}` : '1',
          sell_priority: sell_priority ? `${data.data.sell_priority}` : '1',
          approve_priority: approve_priority ? `${data.data.approve_priority}` : '1',
        }
        dispatch(setUserPrioritySettings(priorityParams))
      }
    }
    fetchSetup()
  }, [userData])

  useEffect(() => {
    if (userCurrentSelectedTemplate.group) {
      const tabIndex = tabs.findIndex(({ label }) => label === userCurrentSelectedTemplate.group)
      setValue(tabIndex)
    }
  }, [userCurrentSelectedTemplate])

  const tabs: TTab[] = useMemo(
    () =>
      [
        {
          label: TemplateGroup.VOLUME,
          content: (
            <div className={styles.tabWrapper}>
              <PairStats variant="pro" />
            </div>
          ),
        },
        {
          label: TemplateGroup.BUY,
          content: <BuyTab />,
        },
        {
          label: 'Sell',
          content: <SellTab />,
        },
        {
          label: TemplateGroup.SNIPE,
          content: <SnipeTab />,
        },
        {
          label: 'Profit/Loss',
          content: <ProfitSellTab />,
        },
      ].filter((item) => !excludeTabs.includes(item.label)),
    [chainName],
  )
  const activePanelTab = localStorage.getItem(LocalStorageItem.ACTIVE_PANEL_TAB)
  const activeTabIndex = tabs.findIndex((tab) => tab.label == activePanelTab)
  const defaultTabIndex = activeTabIndex !== -1 ? activeTabIndex : 0
  return (
    <div className={styles.container}>
      <Tabs
        className={className}
        tabsClasses={styles.tabs}
        value={value}
        onChange={(_, value) => {
          dispatch(clearCurrentSelectedTemplate())
          localStorage.setItem(
            LocalStorageItem.ACTIVE_PANEL_TAB,
            value < tabs.length ? tabs[value].label : TemplateGroup.BUY,
          )
          setValue(value)
        }}
        {...props}
        tabs={tabs}
        defaultValue={tabs.length > Number(defaultTabIndex) ? defaultTabIndex : 0}
      />
    </div>
  )
}

export { BuySellSnipeProfitSell }
