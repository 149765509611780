import { CustomToast } from '@/components/custom-toast'
import { TransactionResponseType } from '@/libs/enums'
import { TDataError, TTransactionResponse, ValueOf } from '@/libs/types'

type TResponseType = ValueOf<typeof TransactionResponseType>

export const processTransactionResponse = (response: TTransactionResponse, type: TResponseType) => {
  if (response.status === 'success' && Array.isArray(response.data.transactions)) {
    response.data.transactions.map((el) => {
      if (el.status === 'success') {
        switch (type) {
          case TransactionResponseType.TRANSFER: {
            CustomToast('success', `The transfer from wallet ${el.name} is successful`)
            break
          }
          case TransactionResponseType.BUY_OR_SELL: {
            CustomToast('info', 'The transaction is pending...')
            break
          }
        }
      }
      if (el.status === 'error') {
        CustomToast('error', `Wallet ${el.name}: ${(el.data as TDataError).description}`)
      }
    })
  }
  if (response.status === 'error') {
    CustomToast('error', `${(response.data as any).description}`)
  }
}
