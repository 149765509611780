import { FC, startTransition, useEffect, useMemo, useRef, useState } from 'react'

import {
  ChartPropertiesOverrides,
  ChartingLibraryFeatureset,
  IChartingLibraryWidget,
  ResolutionString,
  widget,
} from '@/charting_library'
import { SpinnerSize } from '@/libs/enums'
import { store, useAppDispatch, useAppSelector } from '@/store'
import { setShowUserTradesOnChart } from '@/store/slices/app.slice'

import { Spinner } from '../spinner'
import { formatMCap, formatPrice } from './libs/helpers'
import { TSymbolData } from './libs/types'
import { Datafeed } from './libs/utils/datafeed'
import styles from './styles.module.scss'

type TProps = {
  componentWidth: number
}

type TInitChartProps = {
  isPriceSelected?: boolean
  isCurrencyPriceSelected?: boolean
  isShowUserTrades?: boolean
}

const TradingViewNew: FC<TProps> = ({ componentWidth }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const simulation = useAppSelector((state) => state.chain.currentTokenSimulationWebsocket)
  const orderHistory = useAppSelector((state) => state.favoritesTokens.orderHistory)
  const userData = useAppSelector((state) => state.user.userData)
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)
  const showUserTradesOnChart = useAppSelector((state) => state.app.showUserTradesOnChart)

  const needToChangeSymbol = useRef(false)

  const dispatch = useAppDispatch()

  const [isChartIdle, setIsChartIdle] = useState(false)
  const [isChartReady, setIsChartReady] = useState(false)
  const [lastChartProps, setLastChartProps] = useState<TInitChartProps>({
    isPriceSelected: true,
    isCurrencyPriceSelected: false,
    isShowUserTrades: showUserTradesOnChart,
  })

  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const tvWidgetRef = useRef<IChartingLibraryWidget | null>(null)

  const datafeed = useMemo(() => new Datafeed(), [])

  // This function triggers marks recalculation (My Trades)
  const refreshMarks = () => {
    if (!tvWidgetRef.current || !isChartReady || isChartIdle) return
    tvWidgetRef.current.activeChart()?.refreshMarks()
  }

  const setNewResolution = (newRes: string) => {
    localStorage.lastChartResolution = newRes
  }

  const getResolution = () => {
    let res = localStorage.lastChartResolution
    if (!res) {
      res = '1' // Default resolution
      setNewResolution(res)
    }
    return res
  }

  // Get token symbol data fro datafeed's resolveSymbol
  const getTokenSymbol = (calledToSetPairName = false) => {
    const currentToken = store.getState().chain.currentToken
    const symbolData: TSymbolData = {
      tokenAddress: selectedTokenAddress || '',
      calledToSetPairName,
    }
    if (
      selectedTokenAddress &&
      currentToken?.token.address.toLowerCase() === selectedTokenAddress
    ) {
      const secondaryToken =
        currentToken?.token?.address !== currentToken?.pair?.address
          ? currentToken?.pair
          : currentToken?.token
      symbolData.tokenSymbol = `${currentToken?.token?.symbol}/${secondaryToken?.symbol}`
    }

    return JSON.stringify(symbolData)
  }

  // Set new token symbol to the widget
  const setPairSymbol = (widget = tvWidgetRef.current, calledToSetPairName = false) => {
    if (!widget) return

    widget.setSymbol(
      getTokenSymbol(calledToSetPairName),
      widget.symbolInterval().interval,
      () => {},
    )
  }

  // Chart initialisation
  const initializeChart = async (props = lastChartProps) => {
    setIsChartReady(false)
    setLastChartProps(props)

    if (tvWidgetRef.current) {
      tvWidgetRef.current.remove()
      tvWidgetRef.current = null
    }

    try {
      datafeed.connectToWebsocket()
    } catch (err) {
      setTimeout(() => initializeChart(props), 2000)
      return
    }

    // eslint-disable-next-line react/prop-types
    const { isPriceSelected = true, isCurrencyPriceSelected = false, isShowUserTrades } = props

    const disabledFeatures = [
      'header_symbol_search',
      'header_compare',
      'header_saveload',
      'popup_hints',
    ]

    if (componentWidth <= 600) {
      disabledFeatures.push('legend_widget')
    }

    const overrides = {
      'paneProperties.backgroundType': 'solid',
      'paneProperties.background': '#101010',
      'paneProperties.backgroundGradientStartColor': '#101010',
      'paneProperties.backgroundGradientEndColor': '#101010',
    } as ChartPropertiesOverrides

    const tvWidget = new widget({
      symbol: getTokenSymbol(),
      interval: getResolution() as ResolutionString,
      fullscreen: false,
      container: chartContainerRef.current,
      library_path: '/charting_library/',
      theme: 'dark',
      locale: 'en',
      disabled_features: disabledFeatures as ChartingLibraryFeatureset[],
      enabled_features: [
        'adaptive_logo',
        'side_toolbar_in_fullscreen_mode',
        'seconds_resolution',
        'show_spread_operators',
        'context_menus',
      ],
      drawings_access: {
        type: 'black',
        tools: [{ name: 'Regression Trend' }],
      },
      studies_access: {
        type: 'black',
        tools: [{ name: 'HighLow' }],
      },
      charts_storage_url: 'https://saveload.tradingview.com',
      charts_storage_api_version: '1.1',
      client_id: 'tradingview.com',
      user_id: userData?.user_id || 'unknown',
      autosize: true,
      studies_overrides: {},
      auto_save_delay: 2,
      custom_formatters: {
        priceFormatterFactory: () => {
          return {
            format: (price: number) => {
              const simulation = store.getState().chain.currentTokenSimulationWebsocket
              const currentPrice = isCurrencyPriceSelected
                ? price / currentChain.nativeTokenPriceInUsd!
                : price
              return isPriceSelected
                ? formatPrice(currentPrice)
                : // simulation.t.ts (total supply) is not always correct, so we calculate it manually
                  formatMCap(currentPrice, simulation ? +simulation.t.mc / currentPrice : 0)
            },
          }
        },
      },
      overrides,
      loading_screen: {
        backgroundColor: '#101010',
      },
      toolbar_bg: '#101010',
      custom_css_url: '/styles.css',
      datafeed,
    })

    tvWidget.onChartReady(() => {
      tvWidget.applyOverrides(overrides)
      const toChainCurrencyButton = tvWidget.createButton()
      const MCapButton = tvWidget.createButton()
      const showTradesButton = tvWidget.createButton()

      const blue = '#2962ff'

      let isShowTrades = isShowUserTrades
      const selectedCurrency = isCurrencyPriceSelected ? 'USD' : currentChain.chainSymbol
      tvWidget.headerReady().then(() => {
        // USD/ETH button
        toChainCurrencyButton.setAttribute('title', `Switch to ${selectedCurrency}`)
        toChainCurrencyButton.addEventListener('click', () => {
          initializeChart({
            ...props,
            isShowUserTrades: isShowTrades,
            isCurrencyPriceSelected: !isCurrencyPriceSelected,
          })
        })
        toChainCurrencyButton.innerHTML = `<span style="cursor: pointer;">Switch to ${selectedCurrency}</span>`

        // Price/Mcap button
        MCapButton.setAttribute('title', `Switch to ${isPriceSelected ? 'MCap' : 'Price'} chart`)
        MCapButton.addEventListener('click', () => {
          initializeChart({
            ...props,
            isShowUserTrades: isShowTrades,
            isPriceSelected: !isPriceSelected,
          })
        })
        MCapButton.innerHTML = `<div style="cursor: pointer;"><span style="color: ${isPriceSelected && blue};">Price</span> / <span style="color: ${!isPriceSelected && blue};">MCap</span></div>`

        // My Trades button
        showTradesButton.setAttribute('title', `Show my Trades`)
        showTradesButton.addEventListener('click', (e) => {
          const eventTarget = e.target as HTMLButtonElement
          if (!eventTarget) return

          isShowTrades = !isShowTrades
          tvWidgetRef.current = tvWidget
          eventTarget.style.color = isShowTrades ? blue : 'initial'
          dispatch(setShowUserTradesOnChart(isShowTrades))
          setLastChartProps((prev) => ({ ...prev, isShowUserTrades: isShowTrades }))
          setTimeout(() => {
            if (isShowTrades) {
              refreshMarks()
            } else {
              tvWidgetRef.current?.activeChart().clearMarks()
            }
          }, 0)
        })
        showTradesButton.innerHTML = `<div style="cursor: pointer;"><span style="color: ${isShowUserTrades && blue}">Show my Trades</span></div>`
      })

      tvWidget
        .activeChart()
        .onIntervalChanged()
        .subscribe(null, (interval) => {
          setNewResolution(interval)
          startTransition(() => {
            showTradesButton.innerHTML = `<div style="cursor: pointer;"><span style="color: ${isShowTrades && blue}">Show my Trades</span></div>`
          })
        })

      if (needToChangeSymbol.current) {
        setPairSymbol(tvWidget, true)
        needToChangeSymbol.current = false
      }

      setIsChartReady(true)
    })

    tvWidgetRef.current = tvWidget
  }

  // Chart initialisation
  useEffect(() => {
    if (!selectedTokenAddress) return

    initializeChart()
  }, [selectedTokenAddress])

  // Set new pair symbol (only for UI)
  useEffect(() => {
    if (!currentToken) return

    if (isChartReady) {
      setPairSymbol(tvWidgetRef.current, true)
    } else {
      needToChangeSymbol.current = true
    }
  }, [currentToken])

  // Handle app idle state
  useEffect(() => {
    if (isAppIdle === isChartIdle) {
      return
    }

    if (isAppIdle) {
      datafeed.removeDatafeed()
    } else {
      initializeChart()
    }

    setIsChartIdle(isAppIdle)
  }, [isAppIdle])

  // Handle user new transactions
  useEffect(() => {
    if (orderHistory) refreshMarks()
  }, [orderHistory])

  // Remove chart when the component unmounts
  useEffect(() => {
    return () => {
      datafeed.removeDatafeed()
      tvWidgetRef.current?.remove()
    }
  }, [])

  if (!selectedTokenAddress) {
    return <div className={styles.container}>No token</div>
  }

  return (
    <div className={styles.container}>
      <div ref={chartContainerRef} className={styles.chartContainer} />
      {!isChartReady && (
        <div className={styles.loaderContainer}>
          <Spinner size={SpinnerSize.MEDIUM} centered />
        </div>
      )}
    </div>
  )
}

export { TradingViewNew }
