import { useEffect } from 'react'

import { useWatchPanelHistory } from '@/components/app/libs/components/data-watcher/libs/hooks/watch-panels-history.hook.ts'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserWalletsById } from '@/store/slices/user.slice.ts'

import { useWatchBalances } from './libs/hooks/watch-balances.hook'
import { useWatchChainInfo } from './libs/hooks/watch-chain-info.hook'
import { useWatchFavourites } from './libs/hooks/watch-favourites.hook'
import { useWatchHistory } from './libs/hooks/watch-history.hook'
import { useWatchHoldings } from './libs/hooks/watch-holdings.hook'
import { useWatchOrderHistory } from './libs/hooks/watch-order-history.hook.ts'
import { useWatchPanels } from './libs/hooks/watch-panels.hook'
import { useWatchSimulation } from './libs/hooks/watch-simulation.hook'
import { useWatchTransactions } from './libs/hooks/watch-transactions.hook.ts'

const DataWatcher = () => {
  const userData = useAppSelector((state) => state.user.userData)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)

  const dispatch = useAppDispatch()

  // Sniper websockets
  useWatchChainInfo()
  useWatchFavourites()
  useWatchHistory()
  useWatchOrderHistory()
  useWatchSimulation()
  useWatchHoldings()
  useWatchBalances()
  useWatchPanels()
  useWatchPanelHistory()
  useWatchTransactions()

  useEffect(() => {
    if (!userData || isAppIdle) {
      return
    }
    dispatch(fetchUserWalletsById(userData.user_id))
  }, [currentChain, isAppIdle, userData])

  return <></>
}

export { DataWatcher }
