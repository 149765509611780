import { Controller, useFormContext } from 'react-hook-form'

import { Grid } from '@mui/material'

import { chainPriorityCurrencies } from '@/components/buy-tab/libs/constants'
import { SelectWallet } from '@/components/select-wallet'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { ChainName, EManualSettingsSection, IconName } from '@/libs/enums'
import { TManualBuyValidationSchema } from '@/libs/validations/types'
import { useAppSelector } from '@/store'

import { MANUAL_BUY_CHAIN_SETTINGS } from '../../libs/constants'
import styles from '../../styles.module.scss'

const ManualTemplateBuyTab = ({
  chainName,
  templateId,
  isInit,
}: {
  chainName: ChainName
  templateId: string | undefined
  isInit: boolean
}) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const windowDimension = useWindowDimensions()
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<TManualBuyValidationSchema>()
  const chainSettings = MANUAL_BUY_CHAIN_SETTINGS[chainName]
  const isEditAction = !!templateId || !isInit
  const isResponsive = windowDimension.width <= 630

  return (
    <Grid container rowGap={2}>
      <Grid container gap={3} flexWrap="nowrap" flexDirection={isResponsive ? 'column' : 'row'}>
        {chainSettings[EManualSettingsSection.BUY]!.antiMev ?? (
          <Controller
            name="template.ordinaryBuy.privateTransaction"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label="Anti-Mev"
                tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                {...field}
              />
            )}
          />
        )}

        <Controller
          name="template.ordinaryBuy.degenChadMode"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <SwitchInline
              label="Degen Chad Mode"
              tooltipInfo="For advanced traders. If enabled, the safety rules from the simulation will be ignored."
              {...field}
            />
          )}
        />
      </Grid>

      <Controller
        name="template.ordinaryBuy.slippage"
        control={control}
        render={({ field: { ref, ...field } }) => (
          <PercentsInput
            label="Slippage"
            onOptionSelect={(value: any) => field.onChange(value)}
            tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
            placeholder="X"
            error={!!errors.template?.ordinaryBuy?.slippage?.message}
            withAuto={chainSettings[EManualSettingsSection.BUY]!.autoSlippage ?? true}
            {...field}
          />
        )}
      />

      <Controller
        name="template.ordinaryBuy.selectedWallets"
        control={control}
        render={({ field: { ref, value, onChange, ...field } }) => (
          <SelectWallet
            tooltipInfo="Select which wallets will be used to purchase token."
            isMulti
            wallet={value}
            wallets={userWallets}
            isSelectDefaultWallet={!isEditAction}
            onChange={(value: any) => {
              onChange(value)
            }}
            {...field}
          />
        )}
      />

      {chainSettings[EManualSettingsSection.BUY]!.minTokens ?? (
        <Controller
          name="template.advancedBuy.minPercentTokenOrFail"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              className={styles.input}
              label="Min % / Tokens or fail"
              placeholder="Enter token amount or %"
              isNumeric
              tooltipInfo="Minimal percentage of total supply or exact amount in ETH equivalent of tokens that should be bought or the transaction will fail."
              endAdornment={<></>}
              disabled={watch('template.advancedBuy.maxTxOrFail')}
              error={!!errors.template?.advancedBuy?.minPercentTokenOrFail?.message}
              {...field}
            />
          )}
        />
      )}

      {chainSettings[EManualSettingsSection.BUY]!.buyPriority ?? (
        <Controller
          name="template.advancedBuy.buyPriority"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              isNumeric
              className={styles.input}
              label="Buy Priority"
              placeholder={`Enter ${chainPriorityCurrencies[currentChain.chainName]} amount`}
              tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
              endAdornment={
                <EndAdornment
                  label={chainPriorityCurrencies[currentChain.chainName]}
                  icon={(IconName as any)[currentChain.iconName]}
                />
              }
              error={!!errors.template?.advancedBuy?.buyPriority?.message}
              isOptional={chainSettings[EManualSettingsSection.BUY]!.autoSlippage ?? true}
              {...field}
            />
          )}
        />
      )}

      {chainSettings[EManualSettingsSection.BUY]!.spendAmount ?? (
        <Controller
          name="template.ordinaryBuy.buy_amount"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              {...field}
              className={styles.input}
              label="Spend amount"
              placeholder="Enter amount to spend"
              isNumeric
              endAdornment={<></>}
              error={!!errors.template?.ordinaryBuy?.buy_amount?.message}
              value={field.value ? `${field.value}` : ''}
            />
          )}
        />
      )}

      {chainSettings[EManualSettingsSection.BUY]!.approvePriority ?? (
        <Controller
          name="template.advancedBuy.approvePriority"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              className={styles.input}
              label="Approve Priority"
              placeholder={`Enter ${chainPriorityCurrencies[currentChain.chainName]} amount`}
              isNumeric
              tooltipInfo="An extra fee that can be added to speed up the new token approve for you wallet. 1 GWEI = 0.000000001 ETH."
              endAdornment={<></>}
              error={!!errors.template?.advancedBuy?.approvePriority?.message}
              {...field}
            />
          )}
        />
      )}

      <Controller
        name="template.advancedBuy.maxTxOrFail"
        control={control}
        render={({ field: { ref, ...field } }) => (
          <SwitchInline
            label="Max Tx or fail"
            tooltipInfo="Will try to buy maximum available amount of tokens in one transaction. The transaction will fail if this cannot be done."
            {...field}
          />
        )}
      />
    </Grid>
  )
}

export { ManualTemplateBuyTab }
