import { useState } from 'react'

import { ComingSoonOverlay } from '@/components/coming-soon-overlay'
import { ManualSnipeForm } from '@/components/manual-snipe-form'
import { SpendReceiveAmount } from '@/components/spend-receive-amount'
import { TemplateSettingsList } from '@/components/template-settings-list'
import { TemplatesControl } from '@/components/templates-control'
import { TSnipeTemplate } from '@/libs/types/snipe.type'

import styles from './styles.module.scss'
import { AppRoute } from '@/libs/enums'

const mockTemplates: TSnipeTemplate[] = [
  {
    _id: '1',
    name: 'Manual',
  },
  {
    _id: '2',
    name: 'Fast Buy',
  },
  {
    _id: '3',
    name: 'Max profit',
  },
  {
    _id: '4',
    name: 'Quick trade',
  },
]

const SnipeTab = () => {
  const [templates] = useState<TSnipeTemplate[]>(mockTemplates)
  const [currentTemplate, setCurrentTemplate] = useState<TSnipeTemplate | null>(mockTemplates[0])

  const customizeTemplate = () => {
    if (currentTemplate?._id === '2') {
      setCurrentTemplate(mockTemplates[0])
    }
  }

  return (
    <div className={styles.content}>
      {/* <ComingSoonOverlay bottom={-10} top={-10} /> */}
      <TemplatesControl
        templates={templates}
        currentTemplateId={currentTemplate?._id || mockTemplates[0]._id}
        onSelectTemplate={(id) => id}
        onCustomize={customizeTemplate}
        customizeDisabled={currentTemplate?._id === '1'}
        editDisabled={currentTemplate?._id === '1'}
        deleteDisabled={!!currentTemplate && ['1', '2'].includes(currentTemplate?._id)}
        templateGroup={AppRoute.SNIPE_TEMPLATE}
      />
      {currentTemplate?._id === '1' && <ManualSnipeForm buttonName="Snipe" />}
      {currentTemplate?._id === '2' && (
        <>
          <TemplateSettingsList />
          <SpendReceiveAmount buttonTitle="Snipe" />
        </>
      )}
    </div>
  )
}

export { SnipeTab }
