import { useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import { claimAffiliatesFees, getMyAffiliatesInfo } from '@/api/affiliate'
import { TAffilitesData } from '@/api/affiliate/types'
import { CustomToast } from '@/components/custom-toast'
import { Spinner } from '@/components/spinner'
import { Typography, ValueWithCopyAndTitle } from '@/libs/common'
import { IconName, SpinnerSize } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { useAppSelector } from '@/store'

import { AffiliateUserData } from './libs/components/affiliate-user-data'
import { AffiliatesAccordion } from './libs/components/affiliates-accordion'
import { OverviewItem } from './libs/components/overview-item'
import styles from './styles.module.scss'

const Affiliates = () => {
  const userData = useAppSelector((state) => state.user.userData)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const nativeTokeSymbol = currentChain.chainSymbol

  const [affiliatesData, setAffiliatesData] = useState<TAffilitesData | null>(null)
  const [loading, setLoading] = useState(false)
  const [claimLoading, setClaimLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const { data } = await getMyAffiliatesInfo()
        setAffiliatesData(data.data)
      } catch (err) {
        extractErrorDescription(err)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const claim = async () => {
    try {
      setClaimLoading(true)
      await claimAffiliatesFees()
      CustomToast('success', 'You have successfully claimed the fees')
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setClaimLoading(false)
    }
  }

  if (loading) {
    return <Spinner size={SpinnerSize.MEDIUM} centered />
  }

  if (!affiliatesData) {
    return <Typography textAlign="center">No data</Typography>
  }

  return (
    <Grid className={styles.wrapper}>
      <Typography variant="body2" textColor="white" className={styles.title}>
        Overview
      </Typography>
      <div>
        <div className={cn(styles.content, styles.linksContainer)}>
          <ValueWithCopyAndTitle
            title="Web Referral Code"
            value={`${window.location.origin}?affiliateId=${userData?.username}`}
          />
          <ValueWithCopyAndTitle
            title="Telegram Referral Code"
            value={`${import.meta.env.VITE_TG_URL}?start=ref_${userData?.username}`}
          />
        </div>
        <Grid className={cn(styles.content, styles.infoWrapper)}>
          <div className={styles.overview}>
            <OverviewItem
              title="Total Fees Pending To Collect"
              value={
                <>
                  {formatNumber(affiliatesData.total_fees_pending_to_collect.native).formatted}{' '}
                  {nativeTokeSymbol}
                </>
              }
              iconName={IconName.ALARM}
              buttonTitle="Claim"
              onButtonClick={claim}
              buttonLoading={claimLoading}
            />
            <OverviewItem
              title="Totals Fees Collected"
              value={
                <>
                  {formatNumber(affiliatesData.total_fees_collected.native).formatted}{' '}
                  {nativeTokeSymbol}
                </>
              }
              iconName={IconName.FEES_COLLECTED}
            />
            <div className={styles.overviewGroup}>
              <OverviewItem
                title="No. of Signups"
                value={affiliatesData.number_of_signups}
                iconName={IconName.GROUP_OF_PEOPLE}
              />
              <OverviewItem
                title="Referrals Trading Volume"
                value={
                  <>
                    {formatNumber(affiliatesData.referrals_trading_volume.native).formatted}{' '}
                    {nativeTokeSymbol}
                  </>
                }
                iconName={IconName.CANDLESTICK}
                additionalValue={
                  <>(≈${formatNumber(affiliatesData.referrals_trading_volume.usd).formatted})</>
                }
              />
            </div>
            <div className={styles.overviewGroup}>
              <OverviewItem
                title="Commision Tier"
                value={affiliatesData.comission_tier.name}
                iconName={IconName.TROPHY}
              />
              <OverviewItem
                title="Total Fees Earned"
                value={
                  <>
                    {formatNumber(affiliatesData.total_fees_earned.native).formatted}{' '}
                    {nativeTokeSymbol}
                  </>
                }
                iconName={IconName.PRICE_CHECK}
                additionalValue={
                  <>(≈${formatNumber(affiliatesData.total_fees_earned.usd).formatted})</>
                }
              />
            </div>
          </div>

          {affiliatesData.snapshots.map((snapshot, index) => (
            <AffiliatesAccordion
              title={`Snapshot ${moment(snapshot.running_date).format('D/M/YYYY')}`}
              earnings={snapshot.total_fees_to_collect}
              status={snapshot.status}
              key={index}
            >
              <>
                {snapshot.groups.map((group) => (
                  <AffiliatesAccordion
                    key={group.order}
                    title={
                      <Grid display="flex" gap={0.5} alignItems="center">
                        Group {group.order} <Typography>({group.users.length})</Typography>
                      </Grid>
                    }
                    earnings={group.total_fees_to_collect}
                  >
                    <>
                      {group.users.map((user) => (
                        <AffiliatesAccordion
                          key={user.name}
                          title={user.name}
                          earnings={user.total_fees_to_collect}
                        >
                          <AffiliateUserData transactions={user.transactions} />
                        </AffiliatesAccordion>
                      ))}
                      {!group.users.length && (
                        <Typography textAlign="center">No users data</Typography>
                      )}
                    </>
                  </AffiliatesAccordion>
                ))}
                {!snapshot.groups.length && (
                  <Typography textAlign="center">No groups data</Typography>
                )}
              </>
            </AffiliatesAccordion>
          ))}

          {!affiliatesData.snapshots.length && (
            <Typography textAlign="center">No snapshots data</Typography>
          )}
        </Grid>
      </div>
    </Grid>
  )
}

export { Affiliates }
