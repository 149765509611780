import { useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'

// import { useSearchParams } from 'react-router-dom'
import { Grid, InputLabel } from '@mui/material'
import cn from 'classnames'

import { getNewDefaultTemplate } from '@/components/buy-tab/libs/utils/get-default-template'
import { SelectWallet } from '@/components/select-wallet'
import { TemplatesControl } from '@/components/templates-control'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import {
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  HiddenInputs,
  Input,
  StepInput,
  Tabs,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { CopyTradeTemplateAction, EManualSettingsSection, IconName } from '@/libs/enums'
import { createBuyFormStateFromTemplate } from '@/libs/helper/buy/createBuyFormStateFromTemplate'
import { TCopyTradeTransaction } from '@/libs/types/copy-trade-transaction'
import { TSelectOption } from '@/libs/types/select-option.type'
import { TEntityTemplate } from '@/libs/types/template'
import { getTemplateActionTitle } from '@/pages/modal-page/libs/helpers'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { templateDefaultValues } from '../../constants'
import { CopyTradeTemplateSettings } from './libs/components/copy-trade-template-settings'
import { COPYTRADE_CHAIN_SETTINGS } from './libs/constants'
import styles from './styles.module.scss'
import { chainPriorityCurrencies } from '@/components/buy-tab/libs/constants'

const ProfitSettingsTabs: TSelectOption<string>[] = [
  { value: 'sellInSteps', label: 'Sell in steps' },
  { value: 'sellOnProfit', label: 'Sell on profit' },
  { value: 'trailingSell', label: 'Trailing sell' },
]

const manualTemplate = {
  id: 'Manual',
  name: 'Manual',
  isPredefined: true,
}

type TCopyTradeTemplate = TEntityTemplate<TCopyTradeTransaction>

const CopyTradeTemplate = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const userTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const defaultPriorities = useAppSelector((state) => state.user.defaultPriorities)

  const [mainTab, setMainTab] = useState(0)
  const [profitTab, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)
  const [isAntiRugSettings, setIsAntiRugSettings] = useState(false)
  const [isAutoSellSettings, setIsAutoSellSetting] = useState(true)
  const [customizableTemplateId, setCustomizableTemplateId] = useState<string | null>(null)
  const [selectedWallets, setSelectedWallets] = useState<string[]>([])
  const [frontrunBuys, setFrontrunBuys] = useState(false)
  const [frontrunSells, setFrontrunSells] = useState(false)
  const [backrunBuys, setBackrunBuys] = useState(false)
  const [searchParams] = useSearchParams()
  const { setModalProps } = useModal()
  const { templateId } = useParams()
  const windowDimension = useWindowDimensions()

  const isLastMainTab = mainTab === Object.values(EManualSettingsSection).length - 1
  const action = searchParams.get('action')
  const isResponsive = windowDimension.width <= 630

  const templates = useMemo(() => {
    const defaultTemplates = [manualTemplate]
    const mainWallet = userWallets?.find((wallet) => wallet.is_default) || userWallets?.[0]
    const defaultCustomTemplate = getNewDefaultTemplate({
      mainWallet,
      chainName: currentChain.chainName,
    })

    if (defaultCustomTemplate) {
      defaultTemplates.push(defaultCustomTemplate as any)
    }

    return [
      ...defaultTemplates,
      ...(userTemplates?.filter(
        (item) => item.setup?.network.blockchain === currentChain.indexerChainId,
      ) || []),
    ]
  }, [userTemplates, currentChain, userWallets])

  const [currentTemplate, setCurrentTemplate] = useState<
    TCopyTradeTemplate | typeof manualTemplate
  >(manualTemplate)

  const defaultValues = useMemo(() => {
    const defaultWallet = userWallets?.length
      ? userWallets.find((wallet) => wallet.is_default)
      : null
    const initialWallets = defaultWallet ? [defaultWallet.address] : []

    const advancedBuyDefaultValues = {
      buyPriority: defaultPriorities.buy_priority ?? '',
      approvePriority: defaultPriorities.approve_priority ?? '',
    }

    const ordinaryBuyDefaultValues = {
      ...templateDefaultValues.ordinaryBuy,
      selectedWallets: initialWallets,
      receive: 'setup' in currentTemplate ? currentTemplate?.setup?.operation.setup.buy_amount : '',
    }

    const sellDefaultValues = {
      ...templateDefaultValues.sell,
      walletsToSellOnProfit: initialWallets,
      walletsToSellOnLoss: initialWallets,
      selectedWalletsForProfit: initialWallets,
      selectedWalletsForLoss: initialWallets,
      walletsToTrailingSell: initialWallets,
    }

    return {
      ...templateDefaultValues,
      ordinaryBuy: ordinaryBuyDefaultValues,
      advancedBuy: advancedBuyDefaultValues,
      sell: sellDefaultValues,
    }
  }, [userWallets?.length, defaultPriorities])

  const { handleSubmit, control, setValue } = useForm({ defaultValues })

  useEffect(() => {
    setModalProps({
      title: getTemplateActionTitle(CopyTradeTemplateAction.ADD_COPYTRADE),
      titleProps: { className: styles.title },
      withBackButton: true,
    })
  }, [templateId])

  const customizeTemplate = () => {
    if (!currentTemplate) return

    const newValues = createBuyFormStateFromTemplate(currentTemplate as TCopyTradeTemplate).template

    Object.keys(newValues).forEach((key: any) => {
      setValue(key, (newValues as any)[key])
    })

    setCustomizableTemplateId(currentTemplate.id)
    handleTemplateChange(manualTemplate.id)
  }

  const handleTemplateChange = (newId?: string) => {
    const foundTemplate = templates.find((item) => item.id === newId) || null
    // const newTemplate =
    //   foundTemplate && foundTemplate.id !== manualTemplate.id
    //     ? (foundTemplate as TBuyTemplate)
    //     : null

    setCurrentTemplate(foundTemplate as TCopyTradeTemplate)
  }

  const handleProfitTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
    setProfitTab(newValue)
  }

  const handleChangeMainTab = () => {
    setMainTab((value) =>
      value < Object.values(EManualSettingsSection).length ? value + 1 : value,
    )
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    return data
  }

  const chainSettings = COPYTRADE_CHAIN_SETTINGS[currentChain.chainName]
  return (
    <div>
      <Grid className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowGap={3}>
            <Grid container rowGap={3}>
              <Grid width="100%">
                <Input
                  label="Name"
                  placeholder="Enter name"
                  className={styles.input}
                  maxLength={15}
                />

                <Input
                  label="Wallet address"
                  placeholder="Enter wallet address"
                  className={styles.input}
                />
              </Grid>

              <Grid width="100%">
                <TemplatesControl
                  templates={templates}
                  currentTemplateId={currentTemplate?.id || manualTemplate.id}
                  onSelectTemplate={handleTemplateChange}
                  onCustomize={customizeTemplate}
                  customizeDisabled={!currentTemplate}
                  editDisabled={!currentTemplate || currentTemplate.isPredefined}
                  deleteDisabled={!currentTemplate || currentTemplate.isPredefined}
                  isResponsive={isResponsive}
                />
              </Grid>

              {currentTemplate?.id == manualTemplate.id && (
                <Tabs
                  tabControl
                  value={mainTab}
                  tabs={[
                    {
                      label: EManualSettingsSection.BUY,
                      content: (
                        <Grid container rowGap={2}>
                          {chainSettings[EManualSettingsSection.BUY]?.buyAmount ?? (
                            <Controller
                              name="ordinaryBuy.amount"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  isNumeric
                                  className={cn(styles.input, styles.inputSize)}
                                  label="Amount"
                                  placeholder={`Enter ${chainPriorityCurrencies[currentChain.chainName]} amount`}
                                  endAdornment={
                                    <EndAdornment
                                      label={chainPriorityCurrencies[currentChain.chainName]}
                                      icon={(IconName as any)[currentChain.iconName]}
                                    />
                                  }
                                  {...field}
                                />
                              )}
                            />
                          )}

                          <Grid container columnGap={3} flexWrap="nowrap">
                            {chainSettings[EManualSettingsSection.BUY]?.antiMev ?? (
                              <Controller
                                name="ordinaryBuy.privateTransaction"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <SwitchInline
                                    label="Anti-Mev"
                                    tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                                    {...field}
                                  />
                                )}
                              />
                            )}
                            {chainSettings[EManualSettingsSection.BUY]?.degenChadMode ?? (
                              <Controller
                                name="ordinaryBuy.degenChadMode"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <SwitchInline
                                    label="Degen Chad Mode"
                                    tooltipInfo="For advanced traders. If enabled, the safety rules from the simulation will be ignored."
                                    {...field}
                                  />
                                )}
                              />
                            )}
                          </Grid>

                          <Grid container columnGap={3} flexWrap="nowrap">
                            <Controller
                              name="ordinaryBuy.frontrunBuys"
                              control={control}
                              render={({ field: { ref, onChange, ...field } }) => (
                                <SwitchInline
                                  label="Frontrun Buys"
                                  tooltipInfo="Will try to buy faster than other wallet. If it detects transaction initiated by other wallet, it will spend more gas fee (gwei) to prioritize your transaction in network."
                                  onChange={(
                                    _: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean,
                                  ) => {
                                    if (checked && backrunBuys) {
                                      setValue('ordinaryBuy.backrunBuys', false)
                                      setBackrunBuys(false)
                                    }
                                    setFrontrunBuys(checked)
                                    onChange(checked)
                                  }}
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              name="ordinaryBuy.backrunBuys"
                              control={control}
                              render={({ field: { ref, onChange, ...field } }) => (
                                <SwitchInline
                                  label="Backrun Buys"
                                  tooltipInfo="This will make sure that the tracked transaction is prioritized over the your backrun transaction."
                                  onChange={(
                                    _: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean,
                                  ) => {
                                    if (checked && frontrunBuys) {
                                      setValue('ordinaryBuy.frontrunBuys', false)
                                      setFrontrunBuys(false)
                                    }
                                    setBackrunBuys(checked)
                                    onChange(checked)
                                  }}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>

                          {frontrunBuys && (
                            <Controller
                              name="ordinaryBuy.frontrunBuyPriority"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  isNumeric
                                  className={styles.input}
                                  label="Frontrun Priority"
                                  placeholder={`Enter ${chainPriorityCurrencies[currentChain.chainName]} amount`}
                                  tooltipInfo="It will add this much priority gwei to the dev transaction gwei, if developer does 80 and you enter 5 here it will do 85."
                                  endAdornment={
                                    <EndAdornment
                                    label={chainPriorityCurrencies[currentChain.chainName]}
                                    icon={(IconName as any)[currentChain.iconName]}
                                  />
                                  }
                                  {...field}
                                />
                              )}
                            />
                          )}

                          <Controller
                            name="ordinaryBuy.slippage"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <PercentsInput
                                label="Slippage"
                                onOptionSelect={(value: any) => field.onChange(value)}
                                tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                                placeholder="X"
                                {...field}
                              />
                            )}
                          />

                          <Controller
                            name="ordinaryBuy.selectedWallets"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <SelectWallet
                                isMulti
                                wallet={value}
                                wallets={userWallets}
                                tooltipInfo="tooltipInfo"
                                isSelectDefaultWallet={!customizableTemplateId}
                                {...field}
                              />
                            )}
                          />

                          <Grid container display="flex" flexWrap="nowrap" gap={3}>
                            <Controller
                              name="advancedBuy.buyPriority"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  className={styles.input}
                                  label="Buy Priority"
                                  placeholder={`Enter ${chainPriorityCurrencies[currentChain.chainName]} amount`}
                                  isNumeric
                                  tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
                                  onAutoChange={() => {
                                    //TO DO: added auto switch
                                  }}
                                  endAdornment={
                                    <EndAdornment
                                    label={chainPriorityCurrencies[currentChain.chainName]}
                                    icon={(IconName as any)[currentChain.iconName]}
                                  />
                                  }
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              name="advancedBuy.approvePriority"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  className={cn(styles.input, styles.inputSize)}
                                  label="Approve priority"
                                  placeholder={`Enter ${chainPriorityCurrencies[currentChain.chainName]} amount`}
                                  isNumeric
                                  tooltipInfo="An extra fee that can be added to speed up the new token approve for you wallet. 1 GWEI = 0.000000001 ETH."
                                  endAdornment={
                                    <EndAdornment
                                    label={chainPriorityCurrencies[currentChain.chainName]}
                                    icon={(IconName as any)[currentChain.iconName]}
                                  />
                                  }
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      ),
                    },
                    {
                      label: EManualSettingsSection.SAFETY,
                      content: (
                        <Grid container rowGap={2} flexDirection="column">
                          <Grid container columnGap={3} flexWrap="nowrap">
                            <Controller
                              name="shields.transferOnBlackList"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <SwitchInline
                                  label="Transfer On Blacklist"
                                  tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="shields.antiBlackList"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <SwitchInline
                                  label="Anti-Blacklist"
                                  tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>

                          <HiddenInputs
                            expanded={isAntiRugSettings}
                            trigger={
                              <Controller
                                name="shields.antiRug"
                                control={control}
                                render={({ field: { ref, onChange, ...field } }) => (
                                  <SwitchInline
                                    label="Anti-Rug"
                                    tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                                    onChange={(
                                      _: React.ChangeEvent<HTMLInputElement>,
                                      checked: boolean,
                                    ) => {
                                      setIsAntiRugSettings(checked)
                                      onChange(checked)
                                    }}
                                    {...field}
                                  />
                                )}
                              />
                            }
                          >
                            <Grid container rowGap={2}>
                              <Controller
                                name="shields.antiRugThreshold"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <PercentsInput
                                    label="Anti-Rug Threshold"
                                    onOptionSelect={(value: any) => field.onChange(value)}
                                    tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                                    {...field}
                                  />
                                )}
                              />

                              <Controller
                                name="shields.sellRugExtraTip"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder="Amount"
                                    isNumeric
                                    label="Sell Rug Extra Tip"
                                    tooltipInfo="The amount of Gwei that will be added over developer transaction to prioritize your transaction."
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                          </HiddenInputs>

                          <Grid container flexWrap="nowrap" columnGap={3}>
                            <Controller
                              name="shields.buy_tax"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter percentage"
                                  isNumeric
                                  label="Max Buy Tax"
                                  adornmentText="%"
                                  tooltipInfo="The percentage of tokens you will lose when buying. The amount of tax is specified by the developer in the smart contract. If the buy tax exceeds entered value, the transaction will fail."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="shields.sell_tax"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter percentage"
                                  isNumeric
                                  label="Max Sell Tax"
                                  adornmentText="%"
                                  tooltipInfo="Maximum allowable tax percentage when selling a token. If the value is exceeded, the transaction will fail."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid container flexWrap="nowrap" columnGap={3}>
                            <Controller
                              name="shields.minimum_liquidity"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter min liquidity amount"
                                  isNumeric
                                  label="Min Liquidity"
                                  adornmentText="$"
                                  tooltipInfo="The minimum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is below the specified value, the transaction will fail."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="shields.maximum_liquidity"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter max liquidity amount"
                                  isNumeric
                                  label="Max Liquidity"
                                  adornmentText="$"
                                  tooltipInfo="The maximum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is higher the specified value, the transaction will fail."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Controller
                            name="shields.maximum_market_cap"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter max marketcap"
                                isNumeric
                                label="Max Marketcap"
                                adornmentText="$"
                                tooltipInfo="The upper limit of the token's market capitalisation. The transaction will fail if the market cap exceeds the specified value at the moment of its processing."
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      ),
                    },
                    {
                      label: EManualSettingsSection.SELL,
                      content: (
                        <Grid gap={3} container flexDirection="column">
                          <Grid gap={2} container flexDirection="column">
                            <Controller
                              name="sell.copySells"
                              control={control}
                              render={({ field: { ref, onChange, ...field } }) => (
                                <SwitchInline
                                  label="Copy sells"
                                  tooltipInfo="Enable to copy sales transactions of a tracked wallet."
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              name="sell.frontrunSells"
                              control={control}
                              render={({ field: { ref, onChange, ...field } }) => (
                                <SwitchInline
                                  label="Frontrun Sells"
                                  tooltipInfo="Used to sell faster than the tracked wallet. When the bot notices that the tracked wallet is trying to sell a token, it will launch a transaction with a higher priority to beat it."
                                  onChange={(
                                    _: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean,
                                  ) => {
                                    setFrontrunSells(checked)
                                    onChange(checked)
                                  }}
                                  {...field}
                                />
                              )}
                            />
                            {frontrunSells && (
                              <Controller
                                name="sell.frontrunSellPriority"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    className={styles.input}
                                    label="Frontrun Priority"
                                    placeholder={`Enter ${chainPriorityCurrencies[currentChain.chainName]} amount`}
                                    isNumeric
                                    tooltipInfo="It will add this much priority gwei to the dev transaction gwei, if developer does 80 and you enter 5 here it will do 85."
                                    endAdornment={
                                      <EndAdornment
                                      label={chainPriorityCurrencies[currentChain.chainName]}
                                      icon={(IconName as any)[currentChain.iconName]}
                                    />
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            )}
                          </Grid>

                          <HiddenInputs
                            expanded={isAutoSellSettings}
                            trigger={
                              <Controller
                                name="sell.autoSell"
                                control={control}
                                render={({ field: { ref, onChange, ...field } }) => (
                                  <SwitchInline
                                    label="Auto Sell"
                                    tooltipInfo="The bot will automatically sell tokens when the settings you specified are triggered."
                                    {...field}
                                    onChange={(
                                      _: React.ChangeEvent<HTMLInputElement>,
                                      checked: boolean,
                                    ) => {
                                      setIsAutoSellSetting(checked)
                                      onChange(checked)
                                    }}
                                  />
                                )}
                              />
                            }
                          >
                            <Grid container rowGap={2} flexDirection="column">
                              <div className={styles.dividerContainer}>
                                <Typography variant="body2" textColor="grey">
                                  Profit
                                </Typography>

                                <div className={styles.divider}></div>
                              </div>
                              <Controller
                                name="sell.walletsToSellOnProfit"
                                control={control}
                                render={({ field: { ref, value, ...field } }) => {
                                  setSelectedWallets(value)

                                  return (
                                    <SelectWallet
                                      isMulti
                                      tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
                                      wallet={value}
                                      wallets={userWallets}
                                      isSelectDefaultWallet={!customizableTemplateId}
                                      {...field}
                                      label="Wallets to sell on Profit"
                                    />
                                  )
                                }}
                              />

                              <ButtonGroupRadio
                                onChange={handleProfitTabChange}
                                value={profitTab}
                                exclusive
                                className={styles.group}
                              >
                                {ProfitSettingsTabs.map((tab) => (
                                  <ButtonGroupRadioButton
                                    value={tab.value}
                                    key={tab.value}
                                    className={cn({ [styles.bgGrey]: tab.value === profitTab })}
                                  >
                                    {tab.label}
                                  </ButtonGroupRadioButton>
                                ))}
                              </ButtonGroupRadio>

                              {profitTab === ProfitSettingsTabs[0].value &&
                                selectedWallets.length > 0 && (
                                  <div>
                                    <div className={styles.labelContent}>
                                      <InputLabel className={styles.label}>
                                        <Typography variant="body2" textColor="light-grey">
                                          Sell in steps
                                        </Typography>
                                      </InputLabel>
                                      <TooltipIcon
                                        info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%."
                                        marginLeft={5}
                                      />
                                    </div>
                                    <div className={styles.stepWrapper}>
                                      <Controller
                                        name="sell.firstStep"
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                          <StepInput
                                            wallet="#1"
                                            balance="12.58 ETH"
                                            className={styles.firstInput}
                                            {...field}
                                          />
                                        )}
                                      />
                                      <Controller
                                        name="sell.secondStep"
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                          <StepInput wallet="#2" balance="12.58 ETH" {...field} />
                                        )}
                                      />
                                      <Controller
                                        name="sell.thirdStep"
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                          <StepInput
                                            wallet="#3"
                                            balance="12.58 ETH"
                                            className={styles.lastInput}
                                            {...field}
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                )}

                              {profitTab === ProfitSettingsTabs[0].value &&
                                !selectedWallets.length && (
                                  <Typography variant="body1" className={styles.walletsDescr}>
                                    No wallets selected
                                  </Typography>
                                )}

                              {profitTab === ProfitSettingsTabs[1].value && (
                                <Grid container flexWrap="nowrap" columnGap={3}>
                                  <Controller
                                    name="sell.sellOnProfit"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                      <OptionalInput
                                        placeholder="Enter sell on profit"
                                        isNumeric
                                        label="Sell on Profit"
                                        adornmentText="%"
                                        tooltipInfo="Threshold of profit on token position. Sells when the position reaches the specified profit amount."
                                        {...field}
                                      />
                                    )}
                                  />

                                  <Controller
                                    name="sell.sellOnProfitAmount"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                      <OptionalInput
                                        placeholder="Enter sell profit amount"
                                        isNumeric
                                        label="Sell Profit Amount"
                                        adornmentText="%"
                                        tooltipInfo="The percentage of tokens that will be sold when the Take Profit is triggered."
                                        {...field}
                                      />
                                    )}
                                  />
                                </Grid>
                              )}

                              {profitTab === ProfitSettingsTabs[2].value && (
                                <>
                                  <Controller
                                    name="sell.walletsToTrailingSell"
                                    control={control}
                                    render={({ field: { ref, value, ...field } }) => (
                                      <SelectWallet
                                        isMulti
                                        wallets={userWallets}
                                        wallet={value}
                                        label="Wallets to trailing sell"
                                        tooltipInfo="tooltipInfo"
                                        isSelectDefaultWallet={!customizableTemplateId}
                                        {...field}
                                      />
                                    )}
                                  />
                                  <Controller
                                    name="sell.trailingSell"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                      <OptionalInput
                                        placeholder="Enter trailing sell"
                                        isNumeric
                                        label="Trailing Sell"
                                        adornmentText="%"
                                        tooltipInfo="tooltipInfo"
                                        {...field}
                                      />
                                    )}
                                  />
                                </>
                              )}

                              {profitTab !== ProfitSettingsTabs[2].value && (
                                <>
                                  <div className={styles.dividerContainer}>
                                    <Typography variant="body2" textColor="grey">
                                      Loss
                                    </Typography>

                                    <div className={styles.divider}></div>
                                  </div>
                                  <Controller
                                    name="sell.walletsToSellOnLoss"
                                    control={control}
                                    render={({ field: { ref, value, ...field } }) => (
                                      <SelectWallet
                                        label="Wallets to sell on Loss"
                                        isMulti
                                        wallets={userWallets}
                                        tooltipInfo="tooltip"
                                        wallet={value}
                                        isSelectDefaultWallet={!customizableTemplateId}
                                        {...field}
                                      />
                                    )}
                                  />

                                  <Grid container flexWrap="nowrap" columnGap={3}>
                                    <Controller
                                      name="sell.stopLoss"
                                      control={control}
                                      render={({ field: { ref, ...field } }) => (
                                        <OptionalInput
                                          placeholder="Enter amount"
                                          label="Stop Loss"
                                          tooltipInfo="Threshold of loss on token position, exceeding which tokens will be sold."
                                          adornmentText="%"
                                          {...field}
                                        />
                                      )}
                                    />
                                    <Controller
                                      name="sell.stopLossAmount"
                                      control={control}
                                      render={({ field: { ref, ...field } }) => (
                                        <OptionalInput
                                          placeholder="Enter amount"
                                          label="Stop Loss Amount"
                                          tooltipInfo="The percentage of tokens that will be sold when the stop loss is triggered."
                                          adornmentText="%"
                                          {...field}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </HiddenInputs>
                        </Grid>
                      ),
                    },
                  ]}
                />
              )}

              {currentTemplate?.id !== manualTemplate.id && (
                <CopyTradeTemplateSettings
                  template={currentTemplate as TCopyTradeTemplate}
                  expanded={true}
                />
              )}

              {/* {currentTemplate?.id == fastBuyTemplateId && (
                <Grid width="100%" gap={2} container>
                  <Typography variant="body2" textColor="white">
                    Template Settings
                  </Typography>

                  <Grid container flexDirection="column" gap={1.5}>
                    <Typography variant="body2" textColor="white">
                      Buy
                    </Typography>

                    <Grid container gap={3}>
                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Anti-Mev"
                          value={defaultValues.ordinaryBuy.privateTransaction}
                        />

                        <SettingField
                          title="Wallets Selected"
                          value={mockFastTemplateSettings.buy.walletsSelected}
                        />

                        <SettingField
                          title="Approve Priority"
                          value={
                            defaultValues.advancedBuy.approvePriority ||
                            mockFastTemplateSettings.buy.approvePriority
                          }
                        />
                      </Grid>

                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Degen Chad Mode"
                          value={defaultValues.ordinaryBuy.degenChadMode}
                        />

                        <SettingField
                          title="Buy Priority"
                          value={
                            defaultValues.advancedBuy.buyPriority ||
                            mockFastTemplateSettings.buy.buyPriority
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container flexDirection="column" gap={1.5}>
                    <Typography variant="body2" textColor="white">
                      Safety
                    </Typography>

                    <Grid container gap={3}>
                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Anti-Rug"
                          value={
                            defaultValues.shields.antiRug || mockFastTemplateSettings.safety.antiRug
                          }
                        />

                        <SettingField
                          title="Anti-Rug Threshold"
                          value={
                            defaultValues.shields.antiRugThreshold ||
                            mockFastTemplateSettings.safety.antiRugThreshold
                          }
                        />

                        <SettingField
                          title="Sell Rug Extra Tip"
                          value={
                            defaultValues.shields.sellRugExtraTip ||
                            mockFastTemplateSettings.safety.sellRugExtraTip
                          }
                        />

                        <SettingField
                          title="Max Buy Tax"
                          value={
                            defaultValues.shields.buy_tax ||
                            mockFastTemplateSettings.safety.maxBuyTax
                          }
                        />

                        <SettingField
                          title="Min Liquidity"
                          value={
                            defaultValues.shields.minimum_liquidity ||
                            mockFastTemplateSettings.safety.minLiquidity
                          }
                        />
                      </Grid>

                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                         <SettingField
                          title="Anti-Blacklist"
                          value={defaultValues.shields.antiBlacklist}
                        /> 

                        <SettingField
                          title="Transfer On Blacklist"
                          value={defaultValues.shields.transferOnBlackList}
                        />

                        <SettingField
                          title="Max Marketcap"
                          value={
                            defaultValues.shields.maximum_market_cap ||
                            mockFastTemplateSettings.safety.maxMcap
                          }
                        />

                        <SettingField
                          title="Max Sell Tax"
                          value={
                            defaultValues.shields.sell_tax ||
                            mockFastTemplateSettings.safety.maxSellTax
                          }
                        />

                        <SettingField
                          title="Max Liquidity"
                          value={
                            defaultValues.shields.maximum_liquidity ||
                            mockFastTemplateSettings.safety.maxLiquidity
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container flexDirection="column" gap={1.5}>
                    <Typography variant="body2" textColor="white">
                      Sell
                    </Typography>

                    <Grid container gap={3}>
                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Sell Priority"
                          value={
                            defaultValues.sell.sellPriority ||
                            mockFastTemplateSettings.sell.sellPriority
                          }
                        />

                        <SettingField
                          title="Sell on Profit"
                          value={mockFastTemplateSettings.sell.sellOnProfit}
                        />

                        <SettingField
                          title="Wallets to sell on Profit"
                          value={
                            defaultValues.sell.walletsToSellOnProfit && userWallets
                              ? `${defaultValues.sell.walletsToSellOnProfit.length}/${userWallets?.length}`
                              : mockFastTemplateSettings.sell.walletsToSellOnProfit
                          }
                        />

                        <SettingField
                          title="Sell in steps"
                          value={mockFastTemplateSettings.sell.sellInSteps}
                        />
                      </Grid>

                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Auto Sell"
                          value={
                            defaultValues.sell.autoSell || mockFastTemplateSettings.sell.autoSell
                          }
                        />

                        <SettingField
                          title="Sell Profit Amount"
                          value={mockFastTemplateSettings.sell.sellProfitAmount}
                        />

                        <SettingField
                          title="Sell Loss Amount"
                          value={mockFastTemplateSettings.sell.sellLossAmount}
                        />

                        <SettingField
                          title="Trailing Sell"
                          value={mockFastTemplateSettings.sell.trailingSell}
                        />

                        <SettingField title="Sell in steps:" value={''} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )} */}
            </Grid>
            <Grid container columnGap={3} flexWrap="nowrap">
              <Button styleVariant="black" onClick={handleChangeMainTab} disabled={isLastMainTab}>
                Continue
              </Button>

              <Button disabled={!isLastMainTab}>
                {action === 'add-copytrade' ? 'Add' : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  )
}

export { CopyTradeTemplate }
