import { useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { Grid, Stack } from '@mui/material'

import x1 from '@/assets/img/share/1.png'
import x2 from '@/assets/img/share/2.png'
import x3 from '@/assets/img/share/3.png'
import x4 from '@/assets/img/share/4.png'
import x5 from '@/assets/img/share/5.png'
import x6 from '@/assets/img/share/6.png'
import main from '@/assets/img/share/main.png'
import { CustomToast } from '@/components/custom-toast'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Button, Tabs, Typography } from '@/libs/common'
import { CanvasHandle } from '@/libs/types/canvas.type'
import { Canvas } from '@/pages/modal-page/libs/components/share-profit/libs/components/canvas'
import { useAppSelector } from '@/store'

import { HORIZONTAL_OFFSET, MAX_CANVAS_HEIGHT, MAX_CANVAS_WIDTH } from './libs/constants'

const ShareProfit = () => {
  // const [selectedTab, setSelectedTab] = useState(0)
  const canvasRef = useRef<CanvasHandle | null>(null)
  const location = useLocation()
  const tokenInfo = location.state?.positionsSummary || {}
  const { width: windowWidth } = useWindowDimensions()
  const isCanvasFullScreen = windowWidth <= 630

  const currentChainSYmbol = useAppSelector((state) => state.chain.currentChain.chainSymbol)

  const handleSaveCanvasImage = () => {
    const canvas = canvasRef.current?.getCanvas()
    if (canvas) {
      try {
        // Attempt to create a data URL from the canvas
        const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
        const link = document.createElement('a')
        link.href = image
        link.download = 'profit_share.png'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (err) {
        console.error('Error exporting canvas', err)
      }
    }
  }

  const handleCopyToClipboard = async () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current.getCanvas()
      if (canvas) {
        try {
          const dataUrl = canvas.toDataURL('image/png')

          const res = await fetch(dataUrl)
          const blob = await res.blob()

          await navigator.clipboard.write([
            new ClipboardItem({
              'image/png': blob,
            }),
          ])

          CustomToast('success', 'Image copied to clipboard.')
        } catch (error) {
          if (canvasRef.current) {
            const canvas = canvasRef.current.getCanvas()
            const dataUrl = canvas.toDataURL('image/png')
            const a = document.createElement('a')
            a.href = dataUrl
            a.download = 'image.png'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            CustomToast('success', 'Image saved to files.')
          } else {
            CustomToast('error', 'Failed to copy image to clipboard.')
          }
        }
      }
    }
  }

  function getBGByPercentage(percentage: number) {
    if (percentage > 0 && percentage < 100) {
      return x1
    } else if (percentage > 100 && percentage < 200) {
      return x2
    } else if (percentage > 200 && percentage < 300) {
      return x3
    } else if (percentage > 300 && percentage < 400) {
      return x4
    } else if (percentage > 400 && percentage < 500) {
      return x5
    } else if (percentage > 500 && percentage < 1000) {
      return x6
    } else {
      return main
    }
  }
  const sizeRatio = MAX_CANVAS_WIDTH / MAX_CANVAS_HEIGHT
  const isMobile = windowWidth < MAX_CANVAS_WIDTH + 100

  const canvasWidth = isCanvasFullScreen
    ? windowWidth - 32
    : isMobile
      ? windowWidth - HORIZONTAL_OFFSET
      : MAX_CANVAS_WIDTH
  const canvasHeight = canvasWidth / sizeRatio
  const tableTabs = [
    {
      label: 'Horizontal (Desktop)',
      content: tokenInfo.id ? (
        <Canvas
          img={getBGByPercentage(tokenInfo.tpl)}
          width={canvasWidth}
          height={canvasHeight}
          ref={canvasRef}
          isPositionOpen
          tokenInfo={tokenInfo}
          currentChainSYmbol={currentChainSYmbol}
        />
      ) : null,
    },
    // {
    //   label: 'Vertical (Phone)',
    //   content: (
    //     <Canvas
    //       img={main}
    //       width={711}
    //       height={400}
    //       ref={canvasRef}
    //       isPositionOpen={false}
    //       tokenInfo={tokenInfo}
    //       currentChainSYmbol={currentChainSYmbol}
    //     />
    //   ),
    // },
  ]

  // const handleChange = (newValue: number) => {
  //   setSelectedTab(newValue);
  // };

  return (
    <Stack spacing={3} gap={2}>
      <Typography variant="h1" align="center">
        {tokenInfo.tn}
      </Typography>

      <Tabs
        tabs={tableTabs}
        defaultValue={0}
        // handleChangeTab={handleChange}
      />

      <Grid display="flex" gap={2}>
        <Button styleVariant="basic" onClick={handleCopyToClipboard}>
          Copy to clipboard
        </Button>

        <Button styleVariant="basic" onClick={handleSaveCanvasImage}>
          Save image
        </Button>
      </Grid>
    </Stack>
  )
}

export { ShareProfit }
