import { FC, MouseEvent, useMemo, useState } from 'react'

import { Grid, InputAdornment } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import cls from 'classnames'

import { ButtonIcon, Icon, TokenPlaceholder, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'
import { TUserWallet } from '@/libs/types/user.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProperty = {
  isGeneral?: boolean
  wallet?: TUserWallet | undefined
  wallets?: TUserWallet[]
  onTokenSelect: (holding: THoldingsToken | null) => void
}

const AmountDropdown: FC<TProperty> = ({ wallets, onTokenSelect, isGeneral }) => {
  const holdings = useAppSelector((state) => state.user.userHoldingsWebsocket)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const [selectedToken, setSelectedToken] = useState<THoldingsToken | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const walletsAddresses = useMemo(
    () => wallets?.map((item) => item.address.toLowerCase()),
    [wallets],
  )
  const walletsTotalBalance = useMemo(
    () => wallets?.reduce((acc, item) => acc + +(item.balanceFormatted || 0), 0) || 0,
    [wallets],
  )
  const walletHoldings = useMemo(
    () =>
      !isGeneral
        ? holdings?.v.t
            .filter(
              (el) => !!el.w.find((item) => walletsAddresses?.includes(item?.a.toLowerCase())),
            )
            .sort((a, b) => +b.tvu - +a.tvu)
        : holdings && [...holdings.v.t].sort((a, b) => +b.tvu - +a.tvu),
    [walletsAddresses, holdings, isGeneral],
  )

  const isOpen = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <InputAdornment position="end" className={styles.InputAdornment}>
        <Grid></Grid>
        {selectedToken ? (
          <>
            <Grid container className={styles.InputAdornmentIcon}>
              {selectedToken.i?.s ? (
                <img
                  className={styles.tokenLogo}
                  src={selectedToken.i.s}
                  alt={selectedToken.n + ' token'}
                />
              ) : (
                <TokenPlaceholder />
              )}
            </Grid>

            <Typography variant="caption" className={styles.tokenSymbol}>
              {selectedToken.s}
            </Typography>
          </>
        ) : (
          <>
            <Grid container className={styles.InputAdornmentIcon}>
              <Icon name={(IconName as any)[currentChain.iconName]} />
            </Grid>
            <Typography isUpperCase variant="caption">
              {currentChain.chainName}
            </Typography>
          </>
        )}
        <ButtonIcon
          type="button"
          icon={IconName.ARROW_DROP_DOWN}
          onClick={handleClick}
          className={cls(styles.button, { [styles.active]: isOpen })}
        />
      </InputAdornment>
      <Menu
        classes={styles}
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        transformOrigin={{
          vertical: -12,
          horizontal: 560,
        }}
      >
        <MenuItem
          onClick={() => {
            setSelectedToken(null)
            onTokenSelect(null)
            onClose()
          }}
          className={styles.element}
        >
          <div className={styles.content}>
            <div className={styles.token}>
              <Icon name={(IconName as any)[currentChain.iconName]} />
              <div className={styles.tokenInfo}>
                <Typography isUpperCase variant="body2" className={styles.title}>
                  {currentChain.chainName}
                </Typography>
                <Typography variant="body2" className={styles.desc}>
                  {currentChain.label}
                </Typography>
              </div>
            </div>
            <div className={styles.values}>
              <Typography variant="body2" className={styles.title}>
                {isGeneral
                  ? formatNumber(holdings?.v.tvcfw || 0).formatted
                  : formatNumber(walletsTotalBalance).formatted}
              </Typography>
              <Typography variant="body2" className={styles.desc}>
                $
                {isGeneral
                  ? formatNumber(holdings?.v.tvu || 0).formatted
                  : walletsTotalBalance
                    ? formatNumber(walletsTotalBalance * (currentChain.nativeTokenPriceInUsd || 0))
                        .formatted
                    : 0}
              </Typography>
            </div>
          </div>
          {/* <div className={styles.address}>
            <Icon name={IconName.CHECK} />
            <Typography variant="body2" className={styles.text}>
              0x2170ed0880ac9a755fd29b2688956bd959f933f8
            </Typography>
          </div> */}
        </MenuItem>
        {walletHoldings?.map((holding) => {
          const currentWallet = holding.w.find((item) =>
            walletsAddresses?.includes(item.a?.toLowerCase()),
          )
          return (
            <MenuItem
              onClick={() => {
                setSelectedToken(holding)
                onTokenSelect(holding)
                onClose()
              }}
              className={styles.element}
              key={holding.a}
            >
              <div className={styles.content}>
                <div className={styles.token}>
                  <Grid container className={styles.coinIcon}>
                    {holding.i.s ? (
                      <img
                        className={styles.tokenLogo}
                        src={holding.i.s}
                        alt={holding.n + ' token'}
                      />
                    ) : (
                      <TokenPlaceholder />
                    )}
                  </Grid>
                  <div className={styles.tokenInfo}>
                    <Typography variant="body2" className={styles.title}>
                      {holding.s}
                    </Typography>
                    <Typography variant="body2" className={styles.desc}>
                      {holding.n}
                    </Typography>
                  </div>
                </div>
                <div className={styles.values}>
                  <Typography variant="body2" className={styles.title}>
                    {isGeneral
                      ? formatNumber(holding.tb || '0').formatted
                      : currentWallet && formatNumber(currentWallet.b || '0').formatted}
                  </Typography>
                  <Typography variant="body2" className={styles.desc}>
                    {isGeneral
                      ? formatNumber(holding.tvu).formatted
                      : currentWallet && formatNumber(currentWallet.vu).formatted}
                    $
                  </Typography>
                </div>
              </div>
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export { AmountDropdown }
