import { FC, useEffect } from 'react'

import { DepositTab } from '@/components/deposit-tab'
import { useWalletFromParams } from '@/hooks/useWalletFromParams'
import { Tabs, Typography } from '@/libs/common'
import { TabName } from '@/libs/enums'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { HoldingsTab } from './libs/components/holdings-tab'
import { TransferTab } from './libs/components/transfer-tab'
import styles from './styles.module.scss'

const WalletDetails: FC = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const wallet = useWalletFromParams()

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({
      title: wallet?.name || '',
      titleProps: { className: styles.title },
      withBackButton: true,
    })
  }, [wallet])

  return (
    <div>
      <Typography variant="body2" align="center" marginBottom={1}>
        {formatTokenPrice(`${wallet?.balanceFormatted || 0}`).formatted} {currentChain.chainSymbol}{' '}
        (~$
        {(
          +(wallet?.balanceFormatted || 0) * (currentChain.nativeTokenPriceInUsd || 0)
        ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
        )
      </Typography>
      <Tabs
        name={TabName.Wallet_DETAILS}
        tabs={[
          { label: 'Holdings', content: <HoldingsTab wallet={wallet} /> },
          { label: 'Deposit', content: <DepositTab wallet={wallet} /> },
          { label: 'Transfer', content: <TransferTab wallet={wallet} /> },
        ]}
      />
    </div>
  )
}

export { WalletDetails }
